root {
  height: 100dvh;
  width: 100dvw;
}

.App {
  text-align: center;
  height: 100dvh;
  width: 100dvw;
}

html,
body {
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: url("../src/img/background.png");
  background-size: cover;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#images {
  position: absolute;
  width: 65%;
  height: 100%;
  left: 0;
}

#vr-desktop {
  height: 80%;
  left: 0px;
  position: absolute;
}

#people-desktop {
  height: 51%;
  left: 18%;
  bottom: 0px;
  position: fixed;
}

#footerIcons {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 22%;
  bottom: 2%;
  left: 3%;
}

#topFooter {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bottomFooter {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#kementerianIcon {
  height: 70%;
  width: 50%;
}

#famaIcon {
  height: 70%;
  width: 50%;
}

#poweredByIcon {
  height: 28%;
  width: 50%;
}

#vtfIcon {
  height: 150%;
  width: 50%;
}

#topBubble {
  position: absolute;
  right: 0;
  top:0;
  width: 50%;
}

#circle1 {
  position: absolute;
  width: 3%;
  right: 5%;
  top: 30%;
}

#circle2 {
  position: absolute;
  width: 5%;
  top:40%;
  right: 50%;
}

#circle3 {
  position: absolute;
  width: 7%;
  right:5%;
  bottom: 18%;
}

#modal {
  background: linear-gradient(to bottom, #ffffff, #f9faec);
  border-radius: 10px;
  height: 85%;
  width: 32vw;
  position: absolute;
  right: 10%;
  padding-top: 1%;
}

#icons {
  display: flex;
  flex-direction: row;
  height: 10%;
  justify-content: center;
  margin-top: 5%;
  img{
    margin: 0px 2%;
  }

}

#AgroBazaar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 100%;
}

#AgroBazaar img {
  margin-top: 6%;
  max-width: 90%;
}

#inputFields {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60%;
  width: auto;
  padding-left: 8%;
  padding-right: 8%;
}

.inputFieldsContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 25%;
  width: 100%;
  margin-bottom: 2%;
}

#inputFields input {
  width: 95%;
  height: 50%;
  border-radius: 8px;
  border: #dddddd 1px solid;
  font-size: 80%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background: transparent;
}

#selectPlaceholder {
  color: #c2c2c2;
  font-size: large;
}

#inputFields label {
  color: #283c73;
  font-size: 60%;
  font-weight: 500;
  margin-bottom: 2%;
}

.errorMsg {
  color: #E83F36;
  font-size: 0.5rem;
  margin-top: 1%;
  margin-bottom: 0px;
  font-family: 'inter', sans-serif;
  height: 5%;
  width: 100%;
  text-align: start;
}

#age {
  position: relative;
}

#age span:hover {
  cursor: pointer;
}

#age span {
  width: 95%;
  height: 50%;
  border-radius: 8px;
  border: #dddddd 1px solid;
  font-size: 60%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#ageText {
  color: #c2c2c2;
  /* font-size: 80%; */
  width: 92%;
  text-align: start;
  margin: 0px;
  padding-left: 3%;
}

#age img {
  height: 50%;
}

.flipIcon {
  transform: rotate(180deg);
}

#submission {
  padding-left: 8%;
  padding-right: 8%;
  height: 17%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 5%;
}

#submission button {
  width: 100%;
  height: 40%;
  font-size: 80%;
  border: none;
  background: linear-gradient(to bottom, #fb9d1b, #ff7018);
  border-radius: 46px;
  color: white;
  font-weight: bold;
}

#submission button:hover {
  cursor: pointer;
}

#submission p {
  color: black;
  font-size: 40%;
}

#dropdownMenu {
  position: absolute;
  width: 94%;
  background: #efefef;
  /* bottom: -180%; */
  top: 88%;
  border-radius: 8px;
  height: 175%;
  padding: 3%;
}

#dropdownMenu div {
  height: 100%;
  overflow: auto;
}

#dropdownMenu div::-webkit-scrollbar {
  width: 2px;
}

#dropdownMenu div::-webkit-scrollbar-thumb {
  background: #283c73;
  border-radius: 5px;
}

#dropdownMenu p {
  color: #283c73;
  text-align: start;
  height: 18%;
  font-size: 40%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  display: flex;
  align-items: center;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 8px;
  padding-left: 3%;
  width: 95%;
}

#dropdownMenu p:hover {
  background: white;
  cursor: pointer;
}

.selectAgeRange {
  background-color: white;
}

.mobile-only {
  display: none;
}

@media (min-width: 1025px) {
  .desktop-hide-elem {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .mobile-hide-elem {
    display: none !important;
  }

  #images {
    /* position: s; */
    height: 18dvh;
    width: 100%;
    top: 0px;
    
    #vr-desktop {
      height: 100%;
      left: 5%;
      max-width: 48%;
    }
    #people-desktop {
      height: 100%;
      right: 5%;
      left: auto;
      max-width: 48%;
      top: 0px;
      position: absolute;
    }
  }

  #AgroBazaar {
    height: 15%;
  }

  #AgroBazaar img {
    max-width: 50%;
    margin-top:2%;
  }

  #modal {
    position: relative;
    height: 77dvh;
    width: 90dvw;
    right: 0px;
    top: 4%;
  }

  #inputFields {
    justify-content: space-between;
    height: 55%;
    .inputFieldsContainer{
      height: 25%;
      margin-bottom: 3%;
      input, span {
        height: 50%;
      }
    }
  }

  #footerIcons {
    width: 100dvw;
    left: 0px;
    align-items: center;
    bottom: 1dvh;
    position: fixed;
    height: auto;
    #bottomFooter {
      width: 90%;
      height: 5dvh;

      #poweredByIcon{
        width: 25%;
        height: auto;
      }
      #vtfIcon {
        width: 40%;
        margin-left: 5%;
        height: auto;
      }
      
    }
  }

  #submission {
    height: 15%;
    justify-content: start;
    button{
      height: 40%;
    }
    p{
      margin-top: 5%;
      margin-bottom: 0px;
      font-size: 55%;
    }
  }

  #dropdownMenu {
    top: calc(((77dvh * 0.55) * 0.19));
    p{
      font-size: 50%;
    }
  }
}

